import React from 'react'

function Pricing() {
  return (
    <div>
      <section className="pricing">
        <div className="container">
          <h1>Download Full HD images</h1>
          <h5>
            1 image = 1 credit or 0.20 credit per image
            <img src="static/images/icon-info.svg" alt="icon-info" className="pop" data-container="body" data-toggle="popover" data-placement="top" data-content="<strong>Preview Images</strong> up to 0.25 megapixel (eg: 625 x 400) are always free at  website, 0.20 credit will be charged via Apps and API.<span><strong>Full Images</strong> up to 25 megapixel (eg: 6250 x 4000 ) will be charged as 1 credit.</span>" style={{width: '16px', cursor: 'pointer'}} />
          </h5>
          <div className="pricing-row">
            <div className="price-block column-1">
              <div className="priceTop">
                <h2>Free plan</h2>
              </div>
              <div className="priceBottom">
                <div className="free-features">
                  <img src="static/images/free-credits.svg" alt="free-credits" />
                  <span>2 Free credits</span>
                </div>
                <div className="free-features">
                  <img src="static/images/free-preview.svg" alt="free-preview" />
                  <span>Unlimited free previews on </span>
                </div>
                <div className="free-features">
                  <img src="static/images/app-access.svg" alt="app-access" />
                  <span>API access &amp; Apps for windows / Mac / Linux</span>
                </div>
                <div className="free-features">
                  <img src="static/images/phtooshop-1.svg" alt="Photoshop Extension" />
                  <span>Photoshop Extension</span>
                </div>
                <div className="free-features">
                  <img src="static/images/announcement.svg" alt="announcement" />
                  <span>Refer friends and earn free credits</span>
                </div>
                <div className="buttons">
                  <a href="signup" className="btn btn-blue">Sign up for Free</a>
                </div>
              </div>
              <div className="recomended">
                <p><strong>Free Forever:</strong></p>
                <p>Recommended for personal use and evaluation</p>
              </div>
            </div>
            
            {/* Subscription Plan column */}
            <div className="price-block column-2">
              <div className="priceTop">
                <h2>Subscription Plan</h2>
                <h4><span className="currency">$</span><span id="sp_per_image_cost" />
                  <small>/ image</small></h4>
                <span className="popular">Best Value</span>
              </div>
              <div className="priceBottom">
                {/* Subscription plan details */}
              </div>
            </div>

            {/* Top Up column */}
            <div className="price-block column-3">
              <div className="priceTop">
                <h2>Top Up</h2>
                <h4><span className="currency">$</span><span id="tp_per_image_cost" />
                  <small>/ image</small></h4>
              </div>
              <div className="priceBottom pricing-offer">
                {/* Top up plan details */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Other sections like FAQ, etc. can be added here */}
      
    </div>
  )
}

export default Pricing