import React from 'react'

function Refundspolicy() {
  return (
    <div>
      <div className="main-container">
        <section className="affiliate">
          <div className="container">
            <h1>Refund Policy</h1>
            <div className="form-content">
              <p><strong>If you are not 100% satisfied with your purchase, please <a href="infinity-app/contact">contact us</a></strong></p>
              <h4 className="mt-5">14 Days Money Back Guarantee</h4>
              <p>The 14 Days Money Back Guarantee applies if ...</p>
              <ul>
                <li>you have purchased a subscription plan for the first time,</li>
                <li>the subscription plan was purchased within the last 14 days,</li>
                <li>you are not satisfied with the output quality and have no use for cut out images generated by ,</li>
                <li>you have processed a total of less than 50 images in any resolution, and</li>
                <li>your account, you personally and your company have not made use of the 14 Days Money-Back Guarantee before.</li>
              </ul>
              <p>To place a refund request, you would have to fill out <a href="login">this form</a>. We will then review your request within 10 days and contact you via email.</p>
              <h4 className="mt-5">Other Refunds</h4>
              <p>Purchases outside of the 14 Days Money Back Guarantee are generally non-refundable. If you are unhappy with your subscription you can <a href="login">cancel</a> it any time. If you have any questions, feel free to <a href="infinity-app/contact">contact us</a>.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Refundspolicy